import React from "react";
import { FaFileUpload, FaPeopleArrows } from "react-icons/fa";
import { MdRateReview } from "react-icons/md";
import { RiPresentationFill } from "react-icons/ri";
import { FaBusinessTime } from "react-icons/fa6";
import { GiCrane } from "react-icons/gi";
import { FaFileCsv } from "react-icons/fa";
import { SiBim } from "react-icons/si";
import { Si4D } from "react-icons/si";

export const WhyData = [
  {
    id: 3,
    img: "./5d-bim-black.svg",
    icon: <RiPresentationFill size={30} />,
    title: "Win Tender Bids",
    description:
      "Convince project stakeholders with realistic 3D visualizations that demonstrate your construction sequence.",
  },
  {
    id: 3,
    img: "./5d-bim-black.svg",
    icon: <GiCrane size={30} />,
    title: "Reduce Site Risks",
    description:
      "Visualize project buildout and mitigate site risks with virtual design & construction.",
  },
  {
    id: 3,
    img: "./5d-bim-black.svg",
    icon: <FaBusinessTime size={30} />,
    title: "Plan Ahead",
    description:
      "Coordinate complex site works across multiple trades with Critical Path Method (CPM) scheduling.",
  },
];

export const HowData = [
  {
    id: 1,
    img: "",
    icon: <FaFileCsv size={30} />,
    title: "Step 1: Create Project Schedule",
    description:
      "For simpler projects, a simple CSV or Microsoft Project file will suffice. For more complex projects, a P6 schedule may be required.",
  },
  {
    id: 2,
    img: "./4d-bim-black.svg",
    icon: <SiBim size={30} />,
    title: "Step 2: Develop BIM Models",
    description:
      "Tender BIM models tend to have a lower LOD. This is usually sufficient for demonstrating the construction sequence and 4D BIM development.",
  },
  {
    id: 3,
    img: "./5d-bim-black.svg",
    icon: <Si4D size={30} />,
    title: "Step 3: 4D BIM Delivery",
    description:
      "Create highly detailed BIM models with LumenRT or NVIDIA Omniverse for realistic visualizations.",
  },
];

export const faq = [
  {
    id: 1,
    section: "bim-formats",
    question: "What are the types of CAD & BIM formats you support?",
    answer:
      "The most common CAD formats in the built-environment context is autocad (.dwg) and microstation (.dgn). We are able to support to the most common BIM formats such as Revit (.rvt), Archicad (.pln), and IFC.",
  },
  {
    id: 1,
    section: "planning-formats",
    question: "What project schedule formats are typical in 4D BIM?",
    answer:
      "Whether it is a simple CSV file, Microsoft Projects, or a more complex P6 schedule, we can work with most project scheduling formats.",
  },
  {
    id: 3,
    question: "Is there onsite support?",
    answer: "Yes, we work closely with your project team to ensure that CAD & BIM models are created up to the project specifications.",
  },
  {
    id: 4,
    question: "What files do you need to develop the 4D BIM models?",
    answer:
      "A 4D BIM model can be developed with 2D CAD drawings, site survey data, tender BIM models and most importantly the project schedule",
  },
  {
    id: 4,
    section: "corenetx",
    question: "Is 4D BIM needed for CorenetX submissions?",
    answer:
      "CorenetX, formerly known as Corenet 2.0, is a government permitting portal introduced by BCA that mandates BIM model submissions in the openBIM format. While a 4D BIM is not required for Corenet submissions, certain projects (such as LTA) may have 4D BIM submission requirements.",
  },
];
