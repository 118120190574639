import React, { useState } from "react";
import BIM4D from "../../components/_Landing/4DBIM";
import SEO from "../../components/Seo";

const Services4D = () => {
  const [showModal, setShowModal] = useState(true);
  const [category, setCategory] = useState<string | null>(null);
  return <BIM4D />;
};

export default Services4D;

export const Head = ({ location }) => (
  <SEO
    title={
      "BIM Services in Singapore | Create Construction Sequence from BIM"
    }
    description="Need to create a 4D BIM for your project? We work with your team to integrate your project schedules into BIM models for a detailed construction sequence."
    pathname={location.pathname}
  />
);
